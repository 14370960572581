// ==============================
// Custom style
// ==============================

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: monospace;
}
::-moz-placeholder {
    /* Firefox 19+ */
    font-family: monospace;
}
:-ms-input-placeholder {
    /* IE 10+ */
    font-family: monospace;
}
:-moz-placeholder {
    /* Firefox 18- */
    font-family: monospace;
}

#header-desktop {
    .header-wrapper .header-title {
        font-family: monospace;
        font-size: 1.15rem;

        .fa-chevron-right {
            transform: translateX(3px);
        }

        .cursor-block {
            @extend %cursor;
        }
    }

    .progress-container {
        width: 100%;
        height: 2px;
        background: transparent;
    }

    .progress-bar {
        height: 2px;
        background: $global-link-hover-color;
        width: 0%;
        transition: width 0.3s ease;
    }
}

%cursor {
    display: inline-block;
    width: 10px;
    height: 1rem;
    background: $global-link-hover-color;
    border-radius: 1px;
    animation: cursor 1s infinite;
    transform: translate(-4px, 2px);
    border: 0;
}

.ti-cursor {
    @extend %cursor;
    margin-left: 0.3rem !important;
    height: 14px;
    transform: translate(0, 2px);
}

#toc-static {
    margin: 1.5rem 0 3rem;
}

.list,
.home {
    .home-profile {
        font-family: monospace;
        margin: 2rem 0;

        .home-title {
            font-size: 1.5rem;
        }

        .links a:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .summary,
    &[posts] .summary {
        margin-bottom: 3rem;
        border-bottom: 0 !important;

        .single-title {
            font-size: 2rem;
            line-height: 120%;
        }

        .post-footer {
            margin-top: 1rem;
        }
    }

    &[posts] .summary {
        .post-footer {
            justify-content: flex-end;
        }
    }
}

.single {
    .single-title {
        margin: 3.5rem 0 1.5rem;
        font-size: 2rem;
        font-weight: bold;
        line-height: 120%;
    }

    .single-subtitle {
        margin: -0.5rem 0 1.5rem;
        font-size: 1.2rem;
    }

    &.summary .post-meta {
        display: none;
    }

    .post-meta {
        margin-bottom: 3rem;
    }

    .content {
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: bold;
            margin: 4rem 0 1.5rem;
        }

        p {
            margin: 2rem 0;
            font-size: 1.25rem;
            line-height: 1.5;

            &:first-child {
                margin: 1rem 0 2rem;
            }
        }

        img {
            margin: auto;
            display: block;
        }

        blockquote {
            p,
            P:first-child {
                margin: 1rem;
                font-style: italic;
            }
        }

        .admonition {
            margin: 2rem 0;

            &:first-child {
                margin: 1rem 0 2rem;
            }
        }
    }

    .post-info {
        display: flex;
        justify-content: space-between;
    }
}

.special .single-title, .special .single-subtitle {
    text-align: center;
}

.search-dropdown {
    .search-footer {
        display: none;
    }
}

.tag {
    display: inline-block;
    padding: 5px 10px;
    margin-right: 0.5rem;
    font-weight: bold;

    &-javascript {
        &,
        &:hover {
            background: #efd81d !important;
            color:#000 !important;
        }
    }

    &-git {
        &,
        &:hover {
            background: #eb4d28 !important;
            color: #fff !important;
        }
    }

    &-bash {
        &,
        &:hover {
            background: #000 !important;
            color: #fff !important;
        }
    }

    &-nuxt-js {
        &,
        &:hover {
            background: #32475b !important;
            color: #3fb27f !important;
        }
    }

    &-typescript {
        &,
        &:hover {
            background: #3178c6 !important;
            color: #fff !important;
        }
    }
}

.project-links {
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    margin-top: 2rem;

    a {
        margin: 0.5rem;
    }
}


@keyframes cursor {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
