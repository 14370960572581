.projects {
  .intro {
    text-align: center;
    font-family: monospace;
    margin: 4rem 0;

    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1rem;
      font-weight: normal;
    }
  }
}
